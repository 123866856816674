import React, { useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useUrlSearchParams } from '@hooks'

interface IAuthenticateProps extends RouteComponentProps {}
const Authenticate: React.FC<IAuthenticateProps> = () => {
  const { getUrlSearchParam: getUrlSearchAuthParam } = useUrlSearchParams('authorization')
  const urlAuth = getUrlSearchAuthParam()
  const redirectUrl = localStorage.getItem('redirectUrl')

  useEffect(() => {
    if (urlAuth) {
      localStorage.setItem('token', urlAuth)
    }

    navigate(redirectUrl || '/home')
  }, [])
  return <div></div>
}

export default Authenticate
